<!--
 * @Descripttion: 
 * @FilePath: /sxexpress/src/views/contact/index.vue
 * @Author: 张兴业
 * @Date: 2021-03-30 15:12:35
 * @LastEditors: 张兴业
 * @LastEditTime: 2021-09-27 14:17:20
-->
<template>
  <div>
    <form name="form1" method="post" action="./about3.aspx" id="form1">
      <div>
        <input
          type="hidden"
          name="__VIEWSTATE"
          id="__VIEWSTATE"
          value="/wEPDwUKLTM1NzU2MzM0NWRkrUU7C0RkoV1TfwN0woA3JanQU48UkUEXjv15ZU+0tK4="
        />
      </div>

      <div>
        <input
          type="hidden"
          name="__VIEWSTATEGENERATOR"
          id="__VIEWSTATEGENERATOR"
          value="FE23D56C"
        />
      </div>
      <div class="wrap" @click="hidenAsideRight">
        <div class="">
          <div class="header">
            <div class="top tr hidden-phone">
              <div class="w1180">
                <div class="fl">
                  您好，欢迎来到三象速递官网
                </div>
                <div class="fr">
                  www.sxexpress.com.au
                </div>
                <div class="cl"></div>
              </div>
            </div>

            <div class="w1180 clearfix">
              <h2 class="fl pt10 pb10 logo">
                <a href="/"> <img src="@/assets/images/logo.png"/></a>
              </h2>
              <div class="fr pt10 pb10 phone-nav hidden-pc">
                <i class="el-icon-s-unfold" @click.stop="changeMenu"></i>
              </div>
              <div
                class="fr mt20 ml30 hidden-phone"
                style="position: relative;"
              >
                <ul>
                  <li class="fr ml20">
                    <a
                      href="javascript:;"
                      onmouseover="document.getElementById('weixinDIV').style.display = 'block';"
                      onmouseout="document.getElementById('weixinDIV').style.display = 'none';"
                    >
                      <img
                        src="@/assets/images/top-wx.png"
                        alt="与微信客服交流"
                      />
                    </a>
                  </li>
                  <li class="fr">
                    <a
                      href="http://wpa.qq.com/msgrd?v=3&amp;uin=3376684454&amp;site=qq&amp;menu=yes"
                    >
                      <img
                        src="@/assets/images/top-qq.png"
                        alt="与QQ客服交流"
                      />
                    </a>
                  </li>
                </ul>
                <div
                  id="weixinDIV"
                  onmouseover="this.style.display = 'block';"
                  onmouseout="this.style.display = 'none';"
                  style="position: absolute; right: 0px; top: 45px; display: none; z-index: 10000;"
                >
                  <img src="@/assets/images/wx.jpg" style="width: 140px;" />
                </div>
              </div>
              <div class="nav fr hidden-phone">
                <ul>
                  <li><a href="/">首页</a></li>
                  <!-- <li><a href="news.aspx">活动公告</a></li> -->
                  <li><a href="/service">特色服务</a></li>
                  <li><a href="/about">关于三象</a></li>
                  <li><a href="/contact">联系三象</a></li>
                </ul>
              </div>
            </div>
          </div>

          <!--START: Banner-->

          <!--START: Content -->
          <div class="inside fw">
            <div class="inside_banner about_banner">
              <div class="title f14 w1180 " style="color: #fff">
                <div style="margin-left: 25px">
                  <span class="line" style="background: #fff"></span>
                  <h2>联系我们</h2>
                </div>
              </div>
            </div>
            <div class="w1180 clearfix ">
              <div
                class="f14 bgwh contact-all"
                style="padding: 20px; margin-bottom: 30px"
              >
                <div class="contact-title">
                  <img src="@/assets/images/logo-contact.png" />
                </div>
                <div class="clearfix contact-main">
                  <div class="fl col-9">
                    <div class="contact">
                      联系方式：+61 1300965539<br />
                      联系地址：Po Box 6635, Point Cook, VIC 3030<br />
                      周日到周五：10:00-17:30<br />
                      公众号客服服务时间：周一至周五 09:30-18:30
                    </div>
                  </div>
                  <div class="fr col-3 hidden-phone">
                    <img
                      src="@/assets/images/contact_right.jpg"
                      class="about-img"
                    />
                  </div>
                </div>
              </div>
            </div>
            <!--END: Content -->

            <div class="foot">
              <div class="copyright w1180 tc">
                <p>
                  Copyright © 2017-2020 三象速递
                  &nbsp;&nbsp;&nbsp;&nbsp;联系电话：+61 1300965539
                  &nbsp;&nbsp;&nbsp;&nbsp;地址：Po Box 6635, Point Cook, VIC
                  3030&nbsp;&nbsp;&nbsp;&nbsp;<a
                    href="http://www.kuaidi100.com/"
                    target="_blank"
                    >快递查询</a
                  >
                </p>
              </div>
            </div>
            <!-- 代码部分end -->
          </div>
        </div>
      </div>

      <!-- aside right -->
      <aside
        class="ui-aside asideRight w23"
        :style="
          showAsideRight
            ? 'top: 0px; bottom: 0px; right: -1px; width: 120px;'
            : 'top: 0px; bottom: 0px; right: -4rem; width: 0'
        "
      >
        <ul>
          <li>
            <a href="/">首页</a>
          </li>
          <!-- <li>
            <a href="news.aspx">活动公告</a>
          </li> -->
          <li>
            <a href="/service">特色服务</a>
          </li>
          <li>
            <a href="/about">关于三象</a>
          </li>
          <li>
            <a href="/contact">联系三象</a>
          </li>
        </ul>
      </aside>
    </form>
  </div>
</template>

<script>
export default {
  data() {
    return {
      showAsideRight: false
    };
  },
  methods: {
    changeMenu() {
      //移动端右侧滑出
      this.showAsideRight = true;
    },
    hidenAsideRight() {
      this.showAsideRight = false;
    }
  }
};
</script>
